<template>
    <div>
        <CRow>
            <CCol col="12" xl="12">
                    <CDataTable
                        :items="blackList"
                        :fields="blacklistFields"
                        :items-per-page="10"
                        hover
                        fixed
                        :loading="loading"
                        clickable-rows
                        pagination
                        v-on:refresh="blacklistsearch(params.id)"
                        class="modalTable"
                    >
                        <template #errMsg="{item}">
                            <td> <span>{{ item.errMsg }}</span> </td>
                        </template>
                        <template #paymentType="{item}">
                            <td> <span>{{ item.paymentType.friendlyType}}</span> </td>
                        </template>
                        <template #createTime="{item}">
                            <td> <span>{{ dateFormat(item.createTime) }}</span> </td>
                        </template>
                        <template #errCode="{item}">
                            <td style="background-color: #ffe2e2;color:#bf5347;"
                            v-if="item.errCode === '00'|| item.errCode === '04' || item.errCode === '05' || item.errCode === '14' || item.errCode === '43'">
                                <span>{{ item.errCode }}</span>
                            </td>
                            <td v-else>
                                <span>{{ item.errCode }}</span>
                            </td>
                        </template>
                    </CDataTable>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import moment from 'moment'
    export default{
        name: "BlackList",
        props:{
            params: Object,
        },
        computed:{
            blackList: function() {
                return this.$store.getters.blackList
            },
            paymentLog: function() {
                return this.$store.getters.paymentLog
            },
            loading(){
                return this.$store.getters.orderLoading
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                 p: ["PayPalEC", "EFT", "CreditCard", "FreePromo", "DebitCard", "Cash", "GiroGate", "GarantiPay", "IyzicoPay"],
                blacklistFields: [
                    {key: 'id', label: 'ID', _style: 'font-size:12px'},
                    {key: 'createTime', label: 'Olş.Zamanı', _style: 'font-size:12px'},
                    {key: 'errCode', label: 'H.Kodu', _style: 'font-size:12px'},
                    {key: 'errMsg', label: 'H.Mesajı', _style: 'font-size:12px'},
                    {key: 'amount', label: 'Eder', _style: 'font-size:12px'},
                    {key: 'emailAddress', label: 'E-posta', _style: 'font-size:12px'},
                    {key: 'ipAddress', label: 'IP', _style: 'font-size:12px'},
                    {key: 'secure3DFlag', label: '3DSecure', _style: 'font-size:12px'},
                    {key: 'payerID', label: 'Kart No', _style: 'font-size:12px'},
                    {key: 'expDate', label: 'Son Kullanma', _style: 'font-size:12px'},
                    {key: 'payerName', label: 'Kart Sahibi', _style: 'font-size:12px'},
                    {key: 'paymentType', label: 'Ödeme Türü', _style: 'font-size:12px'},
                ],
            }
        },
        methods: {
            blacklistsearch: function(){
                this.$store.dispatch('order_blackListSearch',  {orderId: id})
            },
            dateFormat: function(date){
                return moment(date).format('DD.MM.YYYY HH:mm:ss')
            }
        }
    }
</script>
<style scoped>
     .table-responsive{
        height: auto;
    }
</style>